@use "src/styles/general/colors" as *;

.wipe {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  z-index: 9999;

  &--animation-fadein,
  &--animation-mount {
    // Overlay page with the same color as body has so page content is not visible when a wipe fades in.
    // Before this change next course content was visible for a short (fade-in animation) period of time.
    background: $color-body-bg;
  }

  &--animation-fadeout {
    pointer-events: none;
  }

  &__player,
  .animation-player {
    width: 100%;
    height: 100%;
  }

  .animation-player {
    background: $color-light-grey;

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      pointer-events: none;
    }

    .lottie-player {
      .dotlottie-container {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        aspect-ratio: initial !important;
      }

      canvas {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }
}

@use "src/styles/general/colors" as *;
@use "src/styles/general/breakpoints" as *;
@use "src/styles/general/mixins" as *;

// @todo Is there a use of .activity element without .activity--full on it? If no, remove `full` modifier usage.
.activity--full {
  position: relative;
  display: flex;
  flex-flow: column;
  @include body-large();
  color: $color-white;
  border-radius: 16px;
  overflow: hidden;

  .animated-container {
    z-index: 1;

    &--open,
    &--animation-closing {
      .animated-container__content {
        > .activity__wrapper {
          flex-grow: 0;
          margin-top: auto;
          margin-bottom: auto;
        }
      }
    }

    &--open {
      .animated-container__content {
        border-radius: 0;
        overflow-y: auto;
      }

      .activity__inner,
      .activity__aside {
        justify-content: center;
        padding: 24px;
      }

      .activity__footer {
        margin-top: 0;
      }
    }
  }

  .animated-container,
  .animated-container__scroll,
  .animated-container__content {
    flex: 1;
    display: flex;
    row-gap: 8px;
    flex-flow: column;
  }

  .animated-container__content {
    background-color: $color-navy;
    background-image: url("../../../img/activity-pattern.svg");
    background-size: cover;
    background-position: center bottom;
    background-repeat: no-repeat;
    border-radius: 16px;
  }

  .parse-html {
    @include body-large();

    color: inherit;

    .tooltip {
      color: $color-washed-tangerine;
    }
  }

  // This class is applied to .parse-html, so, it should go after it to apply overrides.
  .tip__body {
    font-size: 14px;
  }

  //@todo Create global styles for form elements.
  input,
  textarea,
  select {
    color: $color-navy;
  }

  .activity__bg {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 0;
    pointer-events: none;

    .animation-player {
      width: 100%;
      height: 100%;

      &__muted {
        display: none;
      }

      .lottie-player {
        .dotlottie-container {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          aspect-ratio: initial !important;
        }

        canvas {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .activity__wrapper {
    display: grid;
    flex: 1;
    z-index: 2;

    &--has-aside {
      @include lg() {
        grid-template-columns: 3fr 2fr;
      }

      .activity__inner {
        @include lg() {
          max-width: 528px;
          padding-left: 72px;
        }
      }
    }
  }

  .activity__inner {
    display: flex;
    flex-flow: column;
    padding: 24px;
    row-gap: 24px;
    width: 100%;

    @include lg() {
      flex: 1;
      max-width: 660px;
      padding: 72px 24px 24px;

      > * {
        width: 100%;
      }

      .video-player--small {
        width: 282px;
      }
    }

    &--align-center {
      text-align: center;
    }
  }

  .activity__aside {
    padding: 16px 24px 24px;

    @include lg() {
      padding: 24px 24px 8px;
    }
  }

  .activity__aside-inner > img {
    display: block;
    margin: 0 auto;

    @include lg {
      width: 100%;
      height: auto;
    }
  }

  &.activity--aside-with-bg .activity__aside-inner {
    // @todo Add box-shadow.
    background: $color-deepblue;
    border-radius: 8px;
    padding: 16px;
  }

  .activity__title {
    @include tags();

    color: $color-washed-tangerine;
    margin-bottom: 8px;
  }

  .activity__content {
    display: flex;
    flex-flow: column;
    align-items: center;
    flex: 1;
    padding: 0;
    min-height: 136px;

    @include lg() {
      border-radius: 16px;
      color: $color-white;
    }
  }

  .activity__footer {
    display: flex;
    flex-flow: column;
    gap: 32px 8px;
    padding: 0 24px 24px;
    margin-top: auto;
    position: relative;
    z-index: 2;

    > .pager {
      margin-left: auto;
    }

    @include lg() {
      flex-flow: row wrap;
      align-items: flex-end;
      pointer-events: none;

      > .pager {
        align-self: flex-end;
      }

      > * {
        pointer-events: initial;
      }

      .video-player--voiceover {
        pointer-events: none;
      }
    }
  }

  &.activity--type-animation {
    min-height: initial;

    .activity__inner {
      padding: 0;
      max-width: initial;
    }

    .activity__footer {
      padding: 8px 8px 16px;

      @include lg() {
        padding: 0 24px 24px;
      }
    }
  }

  &.activity--type-video {
    min-height: 0;

    @include lg() {
      padding: 0;
      .animated-container__content {
        background: none;
      }
    }

    .video-player--default {
      video {
        border-radius: 16px 16px 0 0;
      }

      .player-controls-wrapper {
        position: relative;
        left: auto;
        right: auto;
        top: auto;
        bottom: auto;
        margin-left: 8px;
        margin-right: 8px;
      }

      @include lg() {
        video {
          border-radius: 16px;
        }

        .player-controls-wrapper {
          position: absolute;
          left: 24px;
          bottom: 24px;
          right: auto;
          margin: 0;
        }
      }
    }

    .video-player--small {
      margin-left: auto;
      margin-right: auto;
    }

    .activity__description {
      text-align: center;
    }

    .activity__description + .video-player {
      margin-top: 32px;
    }

    &:not(.activity--has-description) {
      .activity__footer {
        padding: 8px 8px 16px;

        @include lg() {
          position: absolute;
          padding: 0;
          bottom: 24px;
          left: 24px;
          right: 24px;

          > * {
            pointer-events: auto;
          }

          .animated-container__content {
            background: none;
          }
        }
      }
    }
  }

  &.activity--type-slide {
    @include lg() {
      .activity__inner {
        max-width: 528px;
      }
    }
  }

  &.activity--type-quote {
    color: $color-navy;

    .animated-container__content {
      background-color: $color-cream;
      background-image: url("../../../img/quoteBg.svg");
      background-position: center;
      background-size: cover;

      @include lg() {
        background-color: $color-cream;
        background-image: url("../../../img/quoteBg.svg");
      }
    }

    @include lg() {
      background-size: auto;
      //background-position: 8px;
    }

    .activity__content {
      @include lg() {
        color: $color-navy;
      }
    }

    .activity__title {
      color: inherit;
    }

    .activity__description {
      p {
        @include h3h4();

        &.quotecaption {
          @include h5;
        }
      }
    }

    .activity__inner {
      display: flex;
      flex-flow: column;

      @include lg() {
        justify-content: center;
      }
    }
  }

  &.activity--type-river_of_change {
    .activity__description {
      text-align: center;
    }

    .activity__inner {
      @include lg {
        max-width: none;
        padding-left: 48px;
        padding-right: 48px;
      }
    }
  }

  &.activity--type-steps {
    .activity__content {
      flex: 1 100%;
      min-height: 456px;
    }

    .activity__wrapper--complete {
      overflow: auto;

      .activity__inner {
        max-width: none;
      }

      .activity__title,
      .activity__description {
        display: none;
      }
    }

    .activity__inner {
      flex: 1;
      justify-content: center;
      max-width: none;
    }
  }

  &.activity--type-date,
  &.activity--type-drag_words,
  &.activity--type-editable_table,
  &.activity--type-open_question,
  &.activity--type-open_question_multi,
  &.activity--type-options,
  &.activity--type-summary_table,
  &.activity--type-encouragement {
    .activity__inner {
      @include lg() {
        max-width: 460px;
      }
    }
  }

  &.activity--theme-activity,
  &.activity--theme-tool {
    @keyframes activity-gradient {
      0% {
        transform: translate(-50%, -50%) rotate(0deg);
      }
      100% {
        transform: translate(-50%, -50%) rotate(360deg);
      }
    }

    margin: -4px;
    padding: 4px;
    border-radius: 18px;
    overflow: hidden;
    background: none;

    &:before,
    &:after {
      position: absolute;
      display: block;
      content: '';
      z-index: 0;
    }

    &:before {
      left: 50%;
      top: 50%;
      width: calc(100vw + 100vh);
      height: calc(100vw + 100vh);
      width: 100vmax;
      height: 100vmax;
      background-image: conic-gradient(from 180deg, transparent 0%, $color-flame 25%, $color-flame 50%, transparent 75%);
      transform: translate(-50%, -50%);
      animation: activity-gradient 2s linear infinite;
    }

    &:after {
      left: 4px;
      right: 4px;
      top: 4px;
      bottom: 4px;
      border-radius: 16px;
      background-color: $color-deepblue;
    }

    .activity__wrapper {
      z-index: 1;
    }

    .activity__footer {
      z-index: 1;
    }

    .activity__title {
      margin: 0;
      padding: 12px 24px;
      border-radius: 16px 16px 0 0;
      background-color: $color-deepblue;
      color: $color-white;
      box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.10), 0px 0px 1px 0px rgba(0, 0, 0, 0.10), 0px 1px 1px 0px rgba(0, 0, 0, 0.09), 0px 3px 2px 0px rgba(0, 0, 0, 0.05), 0px 5px 2px 0px rgba(0, 0, 0, 0.01), 0px 8px 2px 0px rgba(0, 0, 0, 0.00);
      text-transform: uppercase;

      @include lg() {
        text-align: center;
      }
    }

    .animated-container--open {
      .activity__title {
        border-radius: 0;
      }
    }

    @include lg() {
      &.activity--has-title {
        .activity__inner {
          padding-top: 16px;
        }

        .tips__trigger {
          top: 56px;
        }
      }
    }

    .animated-container__content {
      background-image: none;
    }
  }

  &.activity--theme-tool {
    &:before {
      // @todo Use CSS variable to not repeat the whole gradient rule to just override color.
      // @todo Define Sass variable for the #00A7E1 color though it's currently used only here.
      background-image: conic-gradient(from 180deg, transparent 0%, #00A7E1 25%, #00A7E1 50%, transparent 75%);
    }
  }

  &.activity--theme-intro,
  &.activity--theme-tool-intro {
    .activity__content {
      color: $color-washed-tangerine;
      justify-content: center;
    }

    .activity__inner {
      text-align: center;
      justify-content: center;
      align-items: center;
    }

    .activity__title {
      @include h3();
    }
  }

  &.activity--theme-tool,
  &.activity--theme-tool-intro {
    .animated-container__content {
      background-image: url("../../../img/tool-bg-pattern.png");
      background-position: center top;
      background-size: 100% auto;
    }
  }

  &.activity--theme-reflection {
    .animated-container__content {
      background-color: $color-lightblue;
    }
  }

  > .player-controls-wrapper {
    position: absolute;
    left: 24px;
    bottom: 24px;
  }
}

.activity--voiceover-video {
  .activity__footer {
    .video-player--voiceover {
      position: relative;

      @include lg() {
        .video-player__wrapper,
        .shaka-client-side-ad-container,
        .shaka-controls-container,
        .shaka-spinner-container {

          position: absolute;
          bottom: 100%;
          top: auto;
          margin-bottom: 8px;
        }
      }
    }
  }

  .activity__wrapper {
    .activity__inner {
      @include lg() {
        padding-left: 200px;
        max-width: 860px;
      }
    }
  }

  &.activity--type-open_question {
    .activity__wrapper {
      .activity__inner {
        @include lg() {
          padding-right: 200px;
        }
        @include xl() {
          padding-left: 24px;
          padding-right: 24px;
          max-width: 460px;
        }
      }
    }
  }
}

.activity--type-drag_to_select,
.activity--type-drag_to_sequence {
  .activity__inner {
    @include lg {
      max-width: none;
      padding-left: 72px;
    }
  }
}

.drag-to-select {
  display: grid;
  gap: 24px;
  margin-top: 24px;

  @include lg {
    gap: 40px;
    grid-template-columns: 2fr 1fr;
    margin-top: 32px
  }

  &__answer-container {
    background: rgba($color-black, 0.3);

    &--empty {
      min-height: 48px;
    }

    &--highlighted {
      background: rgba($color-black, 0.2);
    }
  }

  &__answers-container {
    @include lg {
      // Move answers column to the left.
      order: 1;
    }
  }

  &__answers {
    background: $color-deepblue;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 24px;
  }

  &__options-container {
    padding: 0 24px;

    @include lg {
      padding: 0;
    }
  }

  &__options {
    display: grid;
    gap: 8px;

    @include lg {
      grid-template-columns: 1fr 1fr;
    }
  }

  &__answer,
  &__option {
    background: #dbe8f6;
    border-radius: 4px;
    color: $color-navy;
    font-size: 14px;
    font-weight: 600;
    padding: 12px 40px;
    text-align: center;
  }

  &__answer {
    background: $color-tangerine;
    position: relative;

    &--sticky {
      background: $color-white;
    }
  }

  &__option {
    position: relative;

    &--incorrect {
      opacity: 0.25;
    }

    &--placeholder {
      visibility: hidden;
    }
  }

  &__message {
    align-items: center;
    display: flex;
    gap: 8px;
    justify-content: center;
    margin-top: 24px;

    &--hidden {
      visibility: hidden;
    }
  }

  &__icon {
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
  }

  &__separator svg {
    margin: 0 auto;
    transform: rotate(270deg);
  }
}

.drag-to-reorder {
  display: flex;
  flex-direction: column;
  gap: 8px;

  &__option {
    background: $color-light-grey;
    border-radius: 8px;
    color: $color-navy;
    font-size: 14px;
    font-weight: 600;
    padding: 12px 40px;
    position: relative;
    text-align: center;

    &--correct {
      background: $color-tangerine;
    }
  }

  &__option-arrow svg {
    margin: 0 auto;
    transform: rotate(270deg);
  }

  &__option-icon {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);

    .drag-to-reorder__option--correct & {
      left: 12px;
      right: auto;
    }
  }

  &__message {
    align-items: center;
    display: flex;
    font-size: 14px;
    gap: 8px;
    justify-content: center;
    margin-top: 24px;

    @include lg() {
      justify-content: initial;
    }

    // @todo Define `hidden` or `invisible` utility class instead?
    &--hidden {
      visibility: hidden;
    }
  }
}

.river-of-change {
  display: grid;
  gap: 16px 12px;
  margin-top: 16px;

  @include lg {
    grid-template-columns: 1fr 1fr 1fr;
    margin-top: 32px;
  }

  &__start {
    // Needed to place middle description with arrow right after, on mobile.
    order: -2;

    @include lg {
      order: initial;
    }
  }

  &__start,
  &__end {
    background: rgba($color-navy, 0.4);
    border-radius: 8px;
    padding: 16px 24px 24px;

    @include lg {
      padding: 16px 16px 24px;
    }
  }

  &__middle {
    padding: 0 0 24px;

    @include lg {
      padding: 32px 0 0;
    }
  }

  &__title {
    @include h5;

    text-align: center;
  }

  &__items {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 16px;
  }

  &__item {
    border-radius: 4px;
    color: $color-navy;
    font-size: 14px;
    font-weight: 600;
    padding: 8px 12px;

    @include lg {
      font-size: 12px;
      line-height: 16px;
    }

    // @todo Use modifiers on item instead?
    .river-of-change__start & {
      background: $color-washed-orange;
    }

    .river-of-change__middle & {
      color: $color-white;
      text-align: center;
    }

    .river-of-change__end & {
      background: $color-tangerine;
    }
  }

  &__intro {
    color: $color-washed-blue;
    margin-top: 4px;
    text-align: center;

    @include lg {
      display: none;
    }

    .activity & {
      .parse-html,
      .parse-html p {
        font-size: 14px;
      }
    }
  }

  &__description {
    color: $color-washed-blue;
    display: none;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    @include lg {
      display: flex;
      text-transform: uppercase;
    }

    &--middle {
      display: flex;
      order: -1;
      padding: 32px 0 0;
      position: relative;
      text-transform: uppercase;

      @include lg {
        order: initial;
        padding: 4px 0;
      }
    }

    .activity & {
      .parse-html,
      .parse-html p {
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 1px;
        line-height: 16px;

        @include lg {
          font-size: 10px;
        }
      }
    }
  }

  &__arrow {
    left: 50%;
    top: 0;
    transform: translateX(-50%) rotate(90deg) translateX(50%);
    width: 24px;

    @include lg {
      left: 0;
      top: -2px;
      transform: none;
      width: 100%;
    }

    &,
    &::before,
    &::after {
      background: $color-washed-blue;
      border-radius: 1px;
      height: 2px;
      position: absolute;
    }

    &::before,
    &::after {
      content: "";
      display: block;
      right: 1px;
      top: 0;
      transform-origin: right center;
      width: 10px;
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }
  }

  &__wave {
    display: flex;
    justify-content: center;

    //svg {
    //  width: 100%;
    //}

    path {
      // Unique color?
      // @todo Define variable for this color?
      stroke: #b6d3ef;

      @include lg {
        stroke: $color-washed-blue;
      }
    }
  }
}

.options {
  margin-top: 32px;

  &__items {
    display: flex;
    flex-flow: column;
    gap: 16px;
  }

  &__more-button {
    margin-top: 16px;
  }

  &--style-button {
    margin-top: 24px;

    .options__items {
      flex-flow: row wrap;

      .options__item {
        @include button('white', 'outline');

        .chakra-radio__control,
        .chakra-checkbox__control {
          display: none;
        }

        .chakra-radio__label,
        .chakra-checkbox__label {
          margin: 0;
        }
      }
    }
  }
}

.animation-activity {
  flex: 1;
  display: flex;
  row-gap: 8px;
  flex-flow: column;

  @include lg() {
    .activity__footer {
      position: absolute;
      top: auto;
      left: 0;
      right: 0;
      bottom: 0;
    }

    .animation-player__muted {
      top: 24px;
      bottom: auto;
    }
  }
}

.activity--type-animation,
.activity--type-video {
  @include lg() {
    .player-controls,
    .activity__footer {
      opacity: 0;
      transition: opacity .6s ease;
    }

    &.activity--focused {
      .player-controls,
      .activity__footer {
        opacity: 1;
      }
    }
  }
}

.editable-table {
  &--with-reference {
    // Override default table styles due to rowSpan usage, as default styles do not handle rowSpan well.
    .parse-html tbody {
      td:last-child {
        border-right: none;
      }

      td:nth-child(3) {
        border-right: 1px solid #D9D6E3;
      }

      tr:last-child td:first-child {
        border-bottom-left-radius: 0;
      }

      tr:last-child td:last-child:not(.editable-table__cell--last) {
        border-bottom-right-radius: 0;
      }

      // Editable table adds extra classes to bottom left and bottom right cells as there are no way to target these
      // cells via CSS when rowSpan in used.
      // @see https://github.com/whatwg/html/issues/8772
      th.editable-table__cell--last {
        border-bottom: 1px solid #D9D6E3;
        border-bottom-left-radius: 8px;
      }

      td.editable-table__cell--last {
        border-bottom-right-radius: 8px;
      }
    }
  }
}

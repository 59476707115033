@use "src/styles/general/colors" as *;
@use "src/styles/general/mixins" as *;

.resume-course {
  &__content {
    align-items: center;
    background: $color-navy;
    border-radius: 8px 8px 0 0;
    color: $color-white;
    display: flex;
    gap: 12px;
    min-height: 64px;
    overflow: hidden;
  }

  &__thumbnail {
    align-self: stretch;
    flex: 0 0 80px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  // `&__title` selector has not enough specificity to override Charka's styles for heading element.
  .resume-course__title {
    @include h6();
  }

  &__module {
    @include tags();

    font-size: 10px;
    order: -1;
    text-transform: uppercase;
  }

  &__body,
  &__actions {
    padding: 12px 0;
  }

  &__body {
    display: flex;
    flex-direction: column;
  }

  &__actions {
    display: flex;
    gap: 16px;
    margin-left: auto;
    padding-right: 20px;
  }

  &__progressbar {
    > [role=progressbar] {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  // Again, `&__progressbar` selector has not enough specificity to override Charka's styles for progressbar.
  .resume-course__progressbar {
    background: $color-light-grey-2;
  }

  .like:not(.like--liked) path {
    fill: $color-white;
  }
}

@use "src/styles/general/colors" as *;
@use "src/styles/general/breakpoints" as *;
@use "src/styles/general/mixins" as *;

.block--type-intro-video-cta {
  .block__content {
    position: relative;
  }

  .block__actions {
    padding: 24px 0;

    @include lg {
      bottom: 0;
      padding: 24px;
      position: absolute;
      right: 0;
      z-index: 5;
    }

    button {
      width: 100%;

      @include lg {
        width: auto;
      }
    }
  }
}

.block--type-intro-video-replay {

  .block__actions {
    display: none;
    padding: 16px 24px;
  }

  .block__title {
    padding: 16px 0;

    @include lg {
      display: none;
      padding: 24px 0;
    }
  }

  &.block--collapsed .block__content {
    @include lg {
      @include box-shadow;

      align-items: center;
      background: $color-light-grey;
      border-radius: 16px;
      display: flex;
      gap: 24px;
      overflow: hidden;

      .block__actions {
        display: block;
      }

      .block__title {
        display: block;
      }

      .block__video-overlay {
        display: none;

        &--no-tumbnail {
          display: block;
        }
      }

      .block__video-thumbnail {
        order: -1;
        flex: 0 0 128px;
      }
    }
  }

  .block__video-thumbnail,
  .block__video {
    position: relative;
  }

  .block__video-thumbnail img {
    border-radius: 16px;
    width: 100%;

    @include lg {
      border-radius: 0;
    }
  }

  .block__video-overlay {
    background: rgba($color-charcoal, 0.5);
    border-radius: 16px;
    cursor: pointer;
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    width: 100%;

    &--no-thumbnail {
      aspect-ratio: 16 / 9;
      position: static;
    }
  }

  .block__overlay-icon {
    background: rgba($color-charcoal, 0.5);
    border-radius: 50%;
    right: 16px;
    padding: 8px;
    position: absolute;
    top: 16px;
    z-index: 5;
  }

  .block__video-overlay .block__overlay-icon {
    right: 50%;
    top: 50%;
    transform: translate(50%, -50%);
  }
}
